import React, { useState, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import * as formStyles from "../styles/components/form.module.scss";
import classNames from "classnames";
import axios from "axios";
import { Formik, ErrorMessage } from "formik";

const errorClass = (error) => {
  if (error != undefined && error.length != 0) {
    return formStyles.error;
  }
};

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Please Enter Your First Name";
  }

  if (!values.lastName) {
    errors.lastName = "Please Enter your Last Name";
  }

  if (!values.email) {
    errors.email = "Please Include your Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.emailConfirm) {
    errors.emailConfirm = "Please Confirm Your Email";
  } else if (values.email != values.emailConfirm) {
    errors.emailConfirm = "Emails must match";
  }

  if (!values.phone) {
    errors.phone = "Please Enter Your Phone Number";
  }

  if (!values.message) {
    errors.message = "Please include a message";
  }

  if (!values.region) {
    errors.region = "Region is Required";
  }
  return errors;
};

//const multiSelectRef = useRef(null);

const Form = ({ formDisplayText, contactFormRegionMultiselect }) => (
  <div>
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        emailConfirm: "",
        phone: "",
        region: "",
        message: "",
      }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        await axios
          .post(
            "https://pro-code.k2snow.com/api/requests/elevate_contact",
            { values },
            {
              headers: {
                "Access-Control-Allow-Credentials": "false",
              },
              withCredentials: false,
            }
          )
          .then(
            (res) => {
              setSubmitting(true);
              setStatus({
                sent: true,
                msg: "Thank you for contacting Elevate Outdoor Collective! One of our team members will be in touch with you soon.",
              });
            },
            (error) => {
              setStatus({ sent: false, msg: "oh no, there was an error!" });
              console.log("error", error);
            }
          );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        /* and other goodies */
      }) => (
        <section className={formStyles.formContainer}>
          {status && status.sent === true && (
            <div className={formStyles.formSuccess}>
              {console.log(status)}
              <p>{status.msg}</p>
            </div>
          )}

          {(!status || status.sent == false) && (
            <div className={formStyles["formWrapper"]}>
              {status && status.sent === false && (
                <div className={formStyles.sendError}>
                  {console.log(status)}
                  <p>{status.msg}</p>
                </div>
              )}
              <form className={formStyles.form} onSubmit={handleSubmit}>
                <div className={formStyles.formGroup}>
                  <div className={classNames(formStyles.inputGroup)}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.first_name_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.firstName)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <input
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="text"
                      className={classNames(
                        "input",
                        errorClass(errors.firstName)
                      )}
                      type="text"
                      placeholder={formDisplayText.first_name_placeholder}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.last_name_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.lastName)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <input
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="text"
                      className={classNames(
                        "input",
                        errorClass(errors.lastName)
                      )}
                      type="text"
                      placeholder={formDisplayText.last_name_placeholder}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.email_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.email)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <input
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="text"
                      className={classNames("input", errorClass(errors.email))}
                      type="text"
                      placeholder={formDisplayText.email_placeholder}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.confirm_email_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.emailConfirm)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <input
                      name="emailConfirm"
                      value={values.emailConfirm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="confirm email address"
                      className={classNames(
                        "input",
                        errorClass(errors.emailConfirm)
                      )}
                      type="text"
                      placeholder={formDisplayText.confirm_email_placeholder}
                    />
                    <ErrorMessage
                      name="emailConfirm"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.phone_number_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.phone)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <input
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="phone number"
                      className={classNames("input", errorClass(errors.phone))}
                      type="text"
                      placeholder={formDisplayText.phone_number_placeholder}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.region_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.region)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>

                    <Multiselect
                      className={classNames(
                        formStyles.multiselectContainer,
                        errorClass(errors.region)
                      )}
                      options={contactFormRegionMultiselect}
                      name="region"
                      placeholder={formDisplayText.all_placeholder}
                      aria-label="region"
                      onSelect={([{ id: value }]) => {
                        setFieldValue("region", value, true);
                      }}
                      singleSelect
                      displayValue="name"
                    />

                    <ErrorMessage
                      name="region"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                </div>
                <div className={formStyles.formGroup}>
                  <div className={formStyles.inputGroup}>
                    <label
                      className={classNames(
                        formStyles.formLabel,
                        "label-medium"
                      )}
                    >
                      {formDisplayText.message_label}{" "}
                      <span
                        className={classNames(
                          formStyles.required,
                          errorClass(errors.message)
                        )}
                      >
                        ({formDisplayText.required})
                      </span>
                    </label>
                    <textarea
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={formDisplayText.message_label}
                      aria-label="message"
                      rows="4"
                      cols="40"
                      className={classNames(
                        "input",
                        errorClass(errors.message)
                      )}
                    ></textarea>
                    <ErrorMessage
                      name="message"
                      component="div"
                      className={formStyles.errorMessage}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={formStyles.submitBtn}
                  >
                    {formDisplayText.submit_label}
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
      )}
    </Formik>
  </div>
);

export default Form;
