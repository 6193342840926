import React, { useState } from "react";
import Layout from "../../components/layout";
import Herobanner from "../../components/herobanner";
import CallOutSection from "../../components/calloutsection";
import Form from "../../components/form";
import { SEO } from "../../components/seo";
import { graphql } from "gatsby";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const Contact = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundContactUs = getPageByLanguage(
    data.allContentstackContactUs.nodes,
    false,
    cookie
  );
  let foundFormDisplayText = getPageByLanguage(
    data.allContentstackFormDisplayText.nodes,
    false,
    cookie
  );
  let contactFormRegionMultiselect =
    foundFormDisplayText.contact_form_region_multiselect.map((type) => {
      return { name: type, id: type };
    });

  const isBrowser = typeof window !== "undefined";
  if (!foundContactUs && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <Herobanner herobanner={foundContactUs.hero_banner[0]} />
      <Form
        form={foundContactUs.form_inputs}
        formDisplayText={foundFormDisplayText}
        contactFormRegionMultiselect={contactFormRegionMultiselect}
      />
      <CallOutSection calloutsection={foundContactUs.call_out_section[0]} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackContactUs {
      nodes {
        locale
        hero_banner {
          title
          secondary_header
          secondary_header_text
          image_group {
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            image_position
          }
          text
        }
        call_out_section {
          address
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
        form_inputs {
          dropdown_options {
            key
          }
          input_label
          input_type
          placeholder_text
        }
      }
    }
    allContentstackFormDisplayText {
      nodes {
        address_label
        address_placeholder
        all_placeholder
        confirm_email_label
        confirm_email_placeholder
        contact_form_region_multiselect
        country_label
        email_label
        email_placeholder
        first_name_label
        first_name_placeholder
        last_name_label
        last_name_placeholder
        locale
        message_label
        phone_number_label
        phone_number_placeholder
        region_label
        relationship_to_company_label
        required
        submit_label
        type_of_request_label
      }
    }
  }
`;
export default Contact;

export const Head = () => (
  <SEO title="Elevate Outdoor Collective | Contact Us" />
);
